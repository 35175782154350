import { tooltipLoader } from 'components/conceptLink/TooltipLoader';
import { IRefIds } from 'constants/refs';
import * as lodash from 'lodash';
import { del, get, post, put } from 'services/request';
import { IItemId, IPosition } from 'types/content';
import { BaseModel, IModelCourseOptions, IModuleSearch } from './BaseModel';
import { IAIReviewEdit } from '../types/aiReview';

export type IllustrationAiScore = {
  score: number;
  class: string;
}
export type IVoc = {
  id: number;
  title: string;
  wordClass: string;
  notesStatus: number;
  position: IPosition;
  illustration: boolean;
  illustrationAiScore: IllustrationAiScore;
} & {
  [prop: string]: string;
};

export type IVocEdit = {
  id: number;
  title: string;
  wordClass: number;
  vocCategories: number[];
  level: number;
  deletable: boolean;
  wordClassEditable: boolean;
  dialects: IVocDialect[];
  compounds: number[];
  tree: IVocTreeEdit;
  svg: string;
  svg2: string;
  parent: number | null;
  parentSynonym: number | null;
};

export type IVocTreeEdit = {
  id: number;
  title: string;
  current: boolean;
  children: IVocTreeEdit[];
  synonyms: IVocTreeEdit[];
};

export type IVocDialect = {
  id: number;
  vocId: number;
  dialectCode: string;
  dialectId: number;
  langId: number;
  revision: boolean;
  source: string;
  target: string;
  targetWithArticle: string;
  gender: string;
  nInflects: number;
  nInflectsSource: number;
  audioPath: string;
  sourceEditable: boolean;
  sourceShaded: boolean;
  targetShaded: boolean;
  inflectShaded: boolean;
  genderShaded: boolean;
  audioShaded: boolean;
  targetEnabled: boolean;
  targetCanBeDisabled: boolean;
  targetHidden: boolean;
  genderHidden: boolean;
  audioHidden: boolean;
  inflectHidden: boolean;
  wordClass: number;
  aiReview: IAIReviewEdit;
};

type IVocSearch = IModuleSearch & {
  dialect?: string;
  catId?: number;
};

export default class VocabularyModel extends BaseModel<IVoc, IVocEdit> {
  constructor(options: IModelCourseOptions) {
    super(options);
    this.ref = IRefIds.vocs;
    this.gridName = 'Vocs';
    this.getGridColumns();
  }

  async getGridSearch(text: string, dialect?: string) {
    await super.getGrid();

    if (this.gridColumns.length === 0) {
      await this.getGridColumns();
    }
    if (!dialect) {
      dialect = 'title';
    }

    this.gridData = await get<IVoc[]>(`courses/${this.courseId}/vocs?dialect=${dialect}&text=${text}`);

    this.loadingGrid = false;
    this.render();
  }

  async getGridSearchCatId(catId: number) {
    await super.getGrid();

    if (this.gridColumns.length === 0) {
      await this.getGridColumns();
    }

    this.gridData = await get<IVoc[]>(`courses/${this.courseId}/vocs?category=${catId}`);

    this.loadingGrid = false;
    this.render();
  }

  createGridColumnsEndpoint() {
    return `grid-columns/${this.gridName}?course=${this.courseId}`;
  }

  async getGridRow(itemId: number) {
    return await get<IVoc>(`courses/vocs/${itemId}`);
  }

  async new() {
    await super.new();

    if (this.courseId === 0) {
      const [item, dialects] = await Promise.all([
        get<IVocEdit>(`vocs/new`),
        get<IVocDialect[]>(`courses/${this.courseId}/voc-dialects/new`),
      ]);
      item.dialects = dialects;
      await this.setItem(item);
    } else {
      const [item, sources, targets] = await Promise.all([
        get<IVocEdit>(`vocs/new`),
        get<IVocDialect[]>(`courses/${this.courseId}/vocs-target-dialects/new`),
        get<IVocDialect[]>(`courses/${this.courseId}/vocs-source-dialects/new`),
      ]);
      item.dialects = [...targets, ...sources];
      await this.setItem(item);
    }
    this.render();
  }

  async save() {
    await super.save();
    const { id } = this.itemUpdated as IVocEdit;
    if (id === this.NEW_CREATE_ID) {
      await this.createItem();
    } else {
      await this.updateItem();
    }

    this.invalidateTooltip();
  }

  async updateItem() {
    const itemToSave = this.itemUpdated as IVocEdit;
    const itemDialects = this.getItemValue('dialects');
    const { id } = itemToSave;
    let dialectsSave = itemToSave.dialects;
    if (itemToSave.wordClass === this.getItemValue('wordClass')) {
      //update only dialects changed
      dialectsSave = dialectsSave.filter(
        (dialect: IVocDialect, index: number) => JSON.stringify(dialect) !== JSON.stringify(itemDialects[index]),
      );
    }

    await put(`vocs/${id}`, lodash.omit(itemToSave, ['dialects']));

    await Promise.all(
      dialectsSave.map((dialect: IVocDialect) => put(`vocs/${id}/dialects/${dialect.dialectId}`, dialect)),
    );
    await Promise.all([this.updateGridRow(id, 'update'), this.loadItemEdit(id)]);
  }

  async updateItemParent(parent: number | null) {
    await super.save();
    const itemToSave = this.itemUpdated as IVocEdit;
    await put(`/vocs/${itemToSave.id}/parent`, {
      parent: parent ?? 0,
    });

    await this.loadItemEdit(itemToSave.id);
  }

  async updateVocParent(vocId: number, parent: number) {
    await super.save();
    await put(`/vocs/${vocId}/parent`, {
      parent,
    });

    const itemToSave = this.itemUpdated as IVocEdit;
    await this.loadItemEdit(itemToSave.id);
  }

  async updateItemParentSynonym(synonym: number | null) {
    await super.save();
    const itemToSave = this.itemUpdated as IVocEdit;
    await put(`/vocs/${itemToSave.id}/parent-synonym`, {
      parentSynonym: synonym ?? 0,
    });

    await this.loadItemEdit(itemToSave.id);
  }

  async updateVocParentSynonym(vocId: number, synonym: number) {
    await super.save();
    await put(`/vocs/${vocId}/parent-synonym`, {
      parentSynonym: synonym,
    });

    const itemToSave = this.itemUpdated as IVocEdit;
    await this.loadItemEdit(itemToSave.id);
  }

  async createItem() {
    const itemToCreate = this.itemUpdated as IVocEdit;

    const result = await post<IItemId>(`vocs`, lodash.omit(itemToCreate, ['dialects']));

    if (result.ok && result.data) {
      const newItemId = result.data.id;

      await Promise.all([
        itemToCreate.dialects.map((dialect: IVocDialect) =>
          put(`vocs/${newItemId}/dialects/${dialect.dialectId}`, dialect),
        ),
        this.updateGridRow(newItemId, 'add'),
        this.loadItemEdit(newItemId),
      ]);
    }
  }

  async delete() {
    const { id } = this.itemUpdated as IVocEdit;

    const result = await del(`vocs/${id}`);

    if (result.ok) {
      await Promise.all([this.updateGridRow(id, 'remove'), this.new()]);
      this.invalidateTooltip();
    } else {
      console.error(JSON.stringify(result.data));
    }
  }

  async loadItemEdit(itemId: number) {
    await super.loadItemEdit(itemId);

    if (this.courseId === 0) {
      const [item, dialects] = await Promise.all([
        get<IVocEdit>(`vocs/${itemId}`, { courseId: this.courseId }),
        get<IVocDialect[]>(`courses/${this.courseId}/voc-dialects/${itemId}`),
      ]);

      item.dialects = dialects;

      await this.setItem(item);
    } else {
      const [item, targets, sources] = await Promise.all([
        get<IVocEdit>(`vocs/${itemId}`, { courseId: this.courseId }),
        get<IVocDialect[]>(`courses/${this.courseId}/vocs-target-dialects/${itemId}`),
        get<IVocDialect[]>(`courses/${this.courseId}/vocs-source-dialects/${itemId}`),
      ]);

      item.dialects = [...targets, ...sources];

      await this.setItem(item);
    }

    this.render();
  }

  async search(data: IVocSearch) {
    if (typeof data.catId !== 'undefined') {
      await this.getGridSearchCatId(data.catId);
    } else {
      const text = data.text ? data.text : '';
      if (text !== '') {
        await this.getGridSearch(text, data.dialect);
      }
    }
  }

  private invalidateTooltip() {
    tooltipLoader && tooltipLoader.invalidate({ ref: IRefIds.vocs, courseId: this.courseId, id: this.itemId });
  }
}
