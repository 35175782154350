import { get } from 'services/request';
import { IItemId } from '../types/content';

type ICheckerResult = {
  id: number;
};

type IMethod = 'memory' | 'google' | 'delete' | 'copy' | 'ai';

type IModelOptions = {
  render: () => void;
  onDone: () => void;
};

export default class TranslationProjectPreTranslateModel {
  items: number[] = [];
  done: number[] = [];
  projectId: number;
  batchSize: number;
  render: () => void;
  onDone: () => void;
  running = false;
  completed = false;
  method: IMethod = 'copy';

  constructor(options: IModelOptions & { projectId: number }) {
    this.projectId = options.projectId;
    this.batchSize = 10;
    this.render = options.render;
    this.onDone = options.onDone;
  }

  public async preTranslate(method: IMethod) {
    this.method = method;
    this.done = [];
    this.running = false;
    this.completed = false;
    const elements = await get<IItemId[]>(`translations/projects/${this.projectId}/pre-translate/get-items`);

    this.items = elements.map((element) => element.id);
    this.render();
    this.start();
  }

  public toggle() {
    if (this.running) {
      this.stop();
    } else {
      this.start();
    }
  }

  private start() {
    this.running = true;
    this.render();
    this.continue();
  }

  private async continue() {
    if (this.running && !this.completed) {
      const batchIds = this.items.slice(this.done.length, this.done.length + this.batchSize);
      const lastOne = this.done.length + this.batchSize >= this.items.length;
      if (batchIds.length > 0) {
        await get<ICheckerResult[]>(
          `translations/projects/${this.projectId}/pre-translate/pre-translate?ids=${batchIds.join(',')}&method=${
            this.method
          }&analyze=${lastOne ? 1 : 0}`,
        );
        this.done.push(...batchIds);
        this.render();
        await this.continue();
      } else {
        this.completed = true;
        this.stop();
      }
    }
  }

  public stop() {
    if (this.running) {
      this.running = false;
      this.onDone();
      this.render();
    }
  }
}
