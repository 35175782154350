import React, { useContext, useEffect, useState, MouseEvent } from 'react';
import styled from 'styled-components';
import CATTextColor from './CATTextColor';
import CATModel, { ICATMemoryGuest } from '../../../models/CATModel';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import CATSimilarity from './CATSimilarity';
import { CATCell, CATRow } from './CATTableRow';
import { ITranslationStatus } from '../../../types/Translation';
import CATPanel from './CATPanel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IRefIds } from '../../../constants/refs';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { tabsOpened } from '../../../store/actions/tabs';
import { useDispatch } from 'react-redux';

export type IProps = {};

const CATMemory: React.FC<IProps> = () => {
  const dispatch = useDispatch();

  const module = useContext(ModuleContext) as CATModel;
  const [memory, setMemory] = useState<ICATMemoryGuest[] | null>(null);

  useEffect(() => {
    setMemory(module.memoryActiveGuest);
  }, [module, module.memoryActiveGuest]);

  const onSelectMemory = async (row: number) => {
    if (memory && memory[row]) {
      await module.updateTarget(module.activeId, memory[row].text, ITranslationStatus.TRANSLATION_DOING);
      await module.updateTranslationMemory(module.activeId, memory[row].similarity, memory[row].fromText);
    }
  };

  const openTranslation = (event: MouseEvent<SVGSVGElement>, courseId: number, translationId: number) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(
      tabsOpened({
        refId: IRefIds.sourceAdaptation,
        search: translationId.toString(),
        courseId: courseId,
      }),
    );
  };

  const renderMemory = () => {
    if (module.getActive() === null) {
      return null;
    } else if (memory === null) {
      return <CircularProgressStyled size={10} />;
    } else {
      return (
        <>
          {memory.map((m, index) => (
            <CATRowStyled key={index} onClick={() => onSelectMemory(index)}>
              <CATCell>
                <CATTextColorStyled text={m.fromText} />
              </CATCell>
              <CATCell width={30} padding={0}>
                <CATSimilarity similarity={m.similarity} />
              </CATCell>
              <CATCellStyled>
                <CATTextColorStyled text={m.text} />
              </CATCellStyled>
              <CATCellStyled width={20}>
                <FontAwesomeIconEdit icon={faPencilAlt} onClick={(event) => openTranslation(event, m.course, m.id)} />
              </CATCellStyled>
            </CATRowStyled>
          ))}
        </>
      );
    }
  };

  return <CATPanel title={'Memory'}>{renderMemory()}</CATPanel>;
};

export default CATMemory;

const CATRowStyled = styled(CATRow)`
  cursor: pointer;
  border-left: none;
`;

const CATCellStyled = styled(CATCell)`
  border-right: none;
`;

const CATTextColorStyled = styled(CATTextColor)`
  font-size: 14px;
  line-height: 14px;
`;

const CircularProgressStyled = styled(CircularProgress)`
  margin: 4px;
`;

const FontAwesomeIconEdit = styled(FontAwesomeIcon)`
  color: #dddddd;
  font-size: 14px;
  margin: 0;
  cursor: pointer;
`;
