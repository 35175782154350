import React, { useContext, useState } from 'react';
import CATPanel from './CATPanel';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import CATReplace from '../modals/CATReplace';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import CATModel from '../../../models/CATModel';
import CATCheckQA from '../modals/CATCheckQA';

export type IProps = {};

const REPLACE_MODAL = 1;
const QA_MODAL = 2;

const CATActions: React.FC<IProps> = () => {
  const module = useContext(ModuleContext) as CATModel;

  const [modal, setModal] = useState(0);

  const handleCloseModal = () => {
    setModal(0);
  };

  const handleShowModal = (m: number) => {
    setModal(m);
  };

  if (module.projectId === 0) {
    return null;
  }

  return (
    <CATPanel title={'Actions'}>
      <Wrapper>
        <FontAwesomeIconStyled icon={faExchangeAlt} onClick={() => handleShowModal(REPLACE_MODAL)} />
        {module.hasQA() && <FontAwesomeIconStyled icon={faClipboardCheck} onClick={() => handleShowModal(QA_MODAL)} />}
      </Wrapper>
      {modal === REPLACE_MODAL && <CATReplace onClose={handleCloseModal} />}
      {modal === QA_MODAL && <CATCheckQA onClose={handleCloseModal} />}
    </CATPanel>
  );
};

export default CATActions;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
`;

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  color: gray;
  cursor: pointer;
  margin: 0 8px;
`;
