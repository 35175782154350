import Button from '@material-ui/core/Button';
import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext } from 'react';

type IProps = {
  onSave?: () => void;
};

const SaveButton: React.FC<IProps> = (props) => {
  const module = useContext(ModuleContext);

  const onSave = () => {
    module.save.bind(module)();
    props.onSave && props.onSave();
  };

  return (
    <Button variant="contained" size="small" color="primary" onClick={onSave}>
      save
    </Button>
  );
};

export default SaveButton;
