import ReloadButton from 'CMFW/button/ReloadButton';
import SaveButton from 'CMFW/button/SaveButton';
import ModuleContext from 'CMFW/context/ModuleContext';
import Grid from 'CMFW/grid/Grid';
import TextInput from 'CMFW/input/TextInput';
import ItemLayout from 'CMFW/layout/ItemLayout';
import { LoadingItem } from 'CMFW/layout/Loading';
import {
  ContainerLeft,
  ContainerLeftFooter,
  ContainerRight,
  EditorGap,
  EditorRow,
  ModuleContainer,
} from 'components/common/styles';
import { Split } from 'components/Split';
import { useForceRender } from 'hooks/useForceRender';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Checkbox from '../../CMFW/input/Checkbox';
import CoursesModel from '../../models/CoursesModel';
import LanguageSelect from '../../components/selects/LanguageSelect';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CourseDialectModal from './modal/CourseDialectModal';

const Course: React.FC = () => {
  const forceRender = useForceRender();

  const moduleModel = useMemo(() => new CoursesModel({ render: forceRender }), [forceRender]);

  const sourceAvailableIds: number[] = moduleModel.getItemValue('sourceAvailableIds') ?? [];
  const targetAvailableIds: number[] = moduleModel.getItemValue('targetAvailableIds') ?? [];

  const [showTargetModal, setShowTargetModal] = useState(false);
  const [showSourceModal, setShowSourceModal] = useState(false);

  const handleShowTargetModal = () => {
    setShowTargetModal(true);
  };

  const handleShowSourceModal = () => {
    setShowSourceModal(true);
  };

  const handleHideTargetModal = () => {
    setShowTargetModal(false);
  };

  const handleHideSourceModal = () => {
    setShowSourceModal(false);
  };

  const handleAddSourceDialect = async (dialectId: number, copyDialectId: number) => {
    handleHideSourceModal();
    await moduleModel.addSourceDialect(dialectId, copyDialectId);
  };

  const handleAddTargetDialect = async (dialectId: number, copyDialectId: number) => {
    handleHideTargetModal();
    await moduleModel.addTargetDialect(dialectId, copyDialectId);
  };

  return (
    <ModuleContainer>
      <ModuleContext.Provider value={moduleModel}>
        <Split widthLeft={420}>
          <ContainerLeft>
            <Grid />
            <ContainerLeftFooter>
              <ReloadButton />
            </ContainerLeftFooter>
          </ContainerLeft>
          <ContainerRight>
            <ItemLayout>
              <EditorRow>
                <LanguageSelect disabled={moduleModel.itemId !== 0} keyName={'language'} />
                <TextInputCode keyName={'code'} label={'Code'} />
                <TextInputName keyName={'name'} label={'Name'} />
                <EditorGap />
                <SaveButton />
              </EditorRow>
              <EditorRow>
                <div>
                  Targets: <b>{moduleModel.getItemUpdatedValue('targets')}</b>
                  {targetAvailableIds.length > 0 && <IconPlus icon={faPlus} onClick={handleShowTargetModal} />}
                </div>
              </EditorRow>
              <EditorRow>
                <div>
                  Sources: <b>{moduleModel.getItemUpdatedValue('sources')}</b>
                  {sourceAvailableIds.length > 0 && <IconPlus icon={faPlus} onClick={handleShowSourceModal} />}
                </div>
              </EditorRow>
              <ConfigContainer>
                <CheckboxStyled keyName={'conceptLinker'} label={'Concept Linker'} />
                <EditorGap />
                <CheckboxStyled keyName={'splitExercises'} label={'Split Exercises'} />
                <EditorGap />
                <CheckboxStyled keyName={'hasVocabulary'} label={'Has Vocabulary'} />
                <EditorGap />
                <CheckboxStyled keyName={'playAudioSegmentWhenSolved'} label={'Play Audio Segment after it is Done'} />
                <EditorGap />
                <TextInput type={'number'} keyName={'publishLessonPosition'} label={'Publish Lesson Position'} />
                <EditorGap />
                <TextInput type={'number'} keyName={'clLessonPosition'} label={'CL Lesson Position'} />
              </ConfigContainer>
            </ItemLayout>
            <LoadingItem />
            {showSourceModal && (
              <CourseDialectModal
                title={'Source Dialects'}
                filterIds={sourceAvailableIds}
                onClose={handleHideSourceModal}
                onAdd={handleAddSourceDialect}
                source
              />
            )}
            {showTargetModal && (
              <CourseDialectModal
                title={'Target Dialects'}
                filterIds={targetAvailableIds}
                onClose={handleHideTargetModal}
                onAdd={handleAddTargetDialect}
                target
              />
            )}
          </ContainerRight>
        </Split>
      </ModuleContext.Provider>
    </ModuleContainer>
  );
};

export default Course;

const CheckboxStyled = styled(Checkbox)`
  && {
    display: inline-block;
    width: 200px;
  }
`;

const TextInputCode = styled(TextInput)`
  width: 150px;
`;

const TextInputName = styled(TextInput)`
  width: 200px;
`;

const ConfigContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: 10px;
`;

const IconPlus = styled(FontAwesomeIcon)`
  margin-left: 10px;
  cursor: pointer;
  color: #0f74a8;
`;
