import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress } from '@material-ui/core';
import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import TextArea from 'CMFW/input/TextArea';
import TextEditor from 'CMFW/input/TextEditor';
import TextInput from 'CMFW/input/TextInput';
import { EditorRow, Garbage } from 'components/common/styles';
import { ModalWrapper } from 'components/ModalWrapper';
import PanelsModel, { getInitialClone, IClone } from 'models/PanelsModel';
import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

type IProps = {
  onClose: () => void;
};

const PanelCloneModal: React.FC<IProps> = (props) => {
  const moduleModel = useContext(ModuleContext) as PanelsModel;
  const clones = moduleModel.getItemUpdatedValue('clones') as IClone[];
  const [, setCloneCount] = useState(clones.length);
  const [isSaving, setIsSaving] = useState(false);
  const [doneIndexes, setDoneIndexes] = useState([] as number[]);

  const doneIndexesRef = useRef(doneIndexes);

  useEffect(() => {
    return () => {
      moduleModel.updateItemValue('clones', [getInitialClone()]);
    };
  }, [moduleModel]);

  const handleAddClick = () => {
    moduleModel.updateItemValue('clones', [...clones, getInitialClone()]);
    setCloneCount((count) => count + 1);
  };

  const addDoneIndex = (index: number) => {
    const indexes = [...doneIndexesRef.current, index];
    doneIndexesRef.current = indexes;
    setDoneIndexes(indexes);
  };

  const handleSaveClick = () => {
    setIsSaving(true);
    const promises = moduleModel.clone();
    promises.forEach((promise, index) => {
      promise.then(() => addDoneIndex(index));
    });
  };

  const handleGarbageClick = (index: number) => {
    const updatedClones = clones.slice();
    updatedClones.splice(index, 1);
    moduleModel.updateItemValue('clones', updatedClones);
    setCloneCount((count) => count - 1);
  };

  const getStateElement = (index: number) => {
    if (doneIndexes.indexOf(index) > -1) {
      return <Check icon={faCheck} />;
    } else if (isSaving) {
      return <CircularProgress size={30} />;
    }

    return <Garbage icon={faTrash} onClick={() => handleGarbageClick(index)} />;
  };

  return (
    <ModalWrapper title="Clone Panel" width={558} height={455} onClose={props.onClose}>
      <Scroll>
        {clones.map((clone, index) => (
          <Container key={index}>
            <TextEditor
              keyName={`clones[${index}].title`}
              config={{
                toolBarInline: [['lang_target']],
              }}
              autoGrow
              width={500}
              height={36}
            />
            <EditorRowStyled>
              <TextArea keyName={`clones[${index}].concept`} rowsMin={4} placeholder={'Concept'} />
            </EditorRowStyled>
            <EditorRow>
              <TextInput keyName={`clones[${index}].item`} label={'Item'} />
              <TextInput keyName={`clones[${index}].inflectWord`} label={'Inflect word'} />
              {getStateElement(index)}
            </EditorRow>
          </Container>
        ))}
      </Scroll>
      <Footer>
        <Button variant="contained" size="small" disabled={isSaving} onClick={handleAddClick}>
          add
        </Button>
        <Button variant="contained" size="small" disabled={isSaving} color="primary" onClick={handleSaveClick}>
          save
        </Button>
      </Footer>
    </ModalWrapper>
  );
};

const Scroll = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding-bottom: 10px;
  padding-right: 10px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
`;

const Container = styled.div`
  flex-grow: 1;
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  border-bottom: solid 10px #c3c3c3;
`;

const EditorRowStyled = styled(EditorRow)`
  margin-top: 4px;
`;

const Check = styled(FontAwesomeIcon)`
  color: green;
`;

export default PanelCloneModal;
