import Boards from 'modules/boards/Boards';
import Compounds from 'modules/compounds/Compounds';
import ConceptLinker from 'modules/conceptLinker/ConceptLinker';
import CulturalVoc from 'modules/Culturalvocs/CulturalVocs';
import Glossary from 'modules/glossary/Glossaries';
import Inflects from 'modules/inflects/Inflects';
import PanelIntro from 'modules/panelIntro/PanelIntro';
import Panels from 'modules/panels/Panels';
import PanelsFactory from 'modules/panelsFactory/PanelsFactory';
import Sentence from 'modules/sentence/Sentence';
import SourceAdaptation from 'modules/sourceAdaptation/SourceAdaptation';
import React from 'react';
import styled from 'styled-components';
import { IModuleRequest } from 'types/module';
import { IRefIds } from '../constants/refs';
import Admin from './Admin/Admin';
import Course from './Courses/Course';
import Dialog from './Dialog/Dialog';
import Exercise from './Exercise/Exercise';
import Heading from './Heading/Heading';
import Items from './items/Items';
import KLine from './kLine/Kline';
import LessonOutline from './LessonOutline/LessonOutline';
import LessonTitle from './LessonTitle/LessonTitle';
import PanelTitle from './PanelTitle/PanelTitle';
import Reading from './Reading/Reading';
import Segment from './Segment/Segment';
import Vocabulary from './vocabulary/Vocabulary';
import Publish from './Publish/Publish';
import Audio from './Audio/Audio';
import CLUtils from './CLUtils/CLUtils';
import Conjugation from './Conjugation/Conjugation';
import Languages from './Languages/Languages';
import TableLiterals from './TableLiterals/TableLiterals';
import Table from './Table/Table';
import Chart from './Chart/Chart';
import CAT from './CAT/CAT';
import Feedback from './Feedback/Feedback';
import Book from './Book/Book';
import Trainer from './Trainer/Trainer';
import TtsLab from './TtsLab/TtsLab';
import AIReview from './AIReview/AIReview';
import VocCategory from './VocCategory/VocCategory';

type IProps = {
  module: IModuleRequest;
  isActive: boolean;
};

export const Module: React.FC<IProps> = (props) => {
  const { module } = props;
  const { courseId, search } = module;

  const renderModule = () => {
    switch (module.refId) {
      case IRefIds.admin:
        return <Admin />;

      case IRefIds.courses:
        return <Course />;

      case IRefIds.languages:
        return <Languages />;

      case IRefIds.heading:
        return <Heading />;

      case IRefIds.ttsLab:
        return <TtsLab />;

      case IRefIds.vocabularyCategories:
        return <VocCategory />;

      case IRefIds.klines:
        return <KLine courseId={courseId} />;

      case IRefIds.exercise:
        return <Exercise courseId={courseId} search={search} />;

      case IRefIds.panelIntro:
        return <PanelIntro courseId={courseId} />;

      case IRefIds.glossaries:
        return <Glossary courseId={courseId} search={search} />;

      case IRefIds.cultural:
        return <CulturalVoc courseId={courseId} search={search} />;

      case IRefIds.panels:
        return <Panels courseId={courseId} search={search} />;

      case IRefIds.section:
        return <Inflects courseId={courseId} />;

      case IRefIds.items:
        return <Items courseId={courseId} search={search} />;

      case IRefIds.compounds:
        return <Compounds courseId={courseId} search={search} />;

      case IRefIds.sentences:
        return <Sentence courseId={courseId} search={search} />;

      case IRefIds.panelsFactory:
        return <PanelsFactory courseId={courseId} />;

      case IRefIds.conceptLinker:
        return <ConceptLinker courseId={courseId} />;

      case IRefIds.boards:
        return <Boards courseId={courseId} />;

      case IRefIds.sourceAdaptation:
        return <SourceAdaptation courseId={courseId} search={search} />;

      case IRefIds.vocs:
      case IRefIds.vocsCourse:
        return <Vocabulary courseId={courseId} search={search} />;

      case IRefIds.segment:
        if (courseId !== undefined) {
          return <Segment courseId={courseId} search={search} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.lessonOutline:
        if (courseId !== undefined) {
          return <LessonOutline courseId={courseId} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.dialog:
        if (courseId !== undefined) {
          return <Dialog courseId={courseId} search={search} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.panelTitle:
        if (courseId !== undefined) {
          return <PanelTitle courseId={courseId} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.lessonTitle:
        if (courseId !== undefined) {
          return <LessonTitle courseId={courseId} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.reading:
        if (courseId !== undefined) {
          return <Reading courseId={courseId} search={search} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.publish:
        if (courseId !== undefined) {
          return <Publish courseId={courseId} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.audio:
        if (courseId !== undefined) {
          return <Audio courseId={courseId} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.clUtils:
        if (courseId !== undefined) {
          return <CLUtils courseId={courseId} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.conjugation:
        if (courseId !== undefined) {
          return <Conjugation courseId={courseId} search={search} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.tableLiterals:
        if (courseId !== undefined) {
          return <TableLiterals courseId={courseId} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.table:
        if (courseId !== undefined) {
          return <Table courseId={courseId} search={search} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.chart:
        if (courseId !== undefined) {
          return <Chart courseId={courseId} search={search} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.SDL:
        if (courseId !== undefined) {
          return <CAT courseId={courseId} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.feedback:
        if (courseId !== undefined) {
          return <Feedback courseId={courseId} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.book:
        if (courseId !== undefined) {
          return <Book courseId={courseId} search={search} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.trainer:
        if (courseId !== undefined) {
          return <Trainer courseId={courseId} search={search} />;
        }
        return <span>Missing module data</span>;

      case IRefIds.aIReview:
        if (courseId !== undefined) {
          return <AIReview courseId={courseId} />;
        }
        return <span>Missing module data</span>;

      default:
        return <span>Under construction</span>;
    }
  };
  return <ModuleContainer $isActive={props.isActive}>{renderModule()}</ModuleContainer>;
};

const ModuleContainer = styled.div<{ $isActive: boolean }>`
  display: ${(props) => (props.$isActive ? 'block' : 'none')};
  width: 100%;
  height: 100%;
`;
