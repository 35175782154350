import ModuleContext from 'CMFW/context/ModuleContext';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { IIllustration } from '../../types/illustration';
import { faDownload, faMoon, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalWrapper } from '../../components/ModalWrapper';
import { Button, CircularProgress } from '@material-ui/core';
import { EditorRow } from '../../components/common/styles';
import FileUpload from '../../CMFW/input/FileUpload';
import IllustrationModel from '../../models/IllustrationModel';

enum Extension {
  SVGZ,
  PNG,
}
enum DarkMode {
  LIGHT,
  DARK,
}

const BG_COLOR: Record<DarkMode, string> = {
  [DarkMode.LIGHT]: '#FFFFFF',
  [DarkMode.DARK]: '#2d363f',
};

const FILE_FORMAT: Record<Extension, string> = {
  [Extension.PNG]: 'png',
  [Extension.SVGZ]: 'svg',
};

const Illustration: React.FC = () => {
  const module = useContext(ModuleContext);
  const illustration: IIllustration | undefined = module.getItemValue('illustration');

  const [extension, setExtension] = useState(Extension.SVGZ);
  const [darkMode, setDarkMode] = useState(DarkMode.LIGHT);
  const [showUpload, setShowUpload] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [filePNG, setFilePNG] = useState<File | null>(null);
  const [fileSVG, setFileSVG] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState('');

  const illustrationModel = new IllustrationModel();

  const getFile = () => {
    if (illustration) {
      if (extension === Extension.SVGZ) {
        return illustration.svgz;
      } else {
        return illustration.png;
      }
    }
  };

  const toggleType = () => {
    if (extension === Extension.SVGZ) {
      setExtension(Extension.PNG);
    } else {
      setExtension(Extension.SVGZ);
    }
  };

  const toggleDarkMode = () => {
    if (darkMode === DarkMode.LIGHT) {
      setDarkMode(DarkMode.DARK);
    } else {
      setDarkMode(DarkMode.LIGHT);
    }
  };

  const downloadFile = (file: string) => {
    const fileLink = document.createElement('a');
    fileLink.href = file;
    fileLink.download = file.split('/').pop() as string;
    console.log(fileLink);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  };

  const download = () => {
    if (illustration) {
      downloadFile(illustration.png);
      downloadFile(illustration.svg);
    }
  };

  const handleShowUpload = () => {
    setShowUpload(true);
  };

  const closeShowUpload = () => {
    setShowUpload(false);
    setFileSVG(null);
    setFilePNG(null);
  };

  const handleShowDelete = () => {
    setShowDelete(true);
  };

  const closeShowDelete = () => {
    setShowDelete(false);
  };

  const handleFileChosenPNG = (file: File) => {
    setFilePNG(file);
  };

  const handleFileChosenSVG = (file: File) => {
    setFileSVG(file);
  };

  const handleUploadClick = async () => {
    if (!filePNG || !fileSVG || !illustration) {
      return;
    }
    setIsUploading(true);
    setMessage('');
    const response = await illustrationModel.uploadFile(illustration, fileSVG, filePNG);

    if (!response) {
      setMessage('Something went wrong, try again.');
    } else {
      if (response.status === 1) {
        closeShowUpload();
        module.reloadItem();
      } else {
        setMessage(`<p><b>File with errors:</b></p><p>${response.error}</p>`);
      }
    }
    setIsUploading(false);
  };

  const handleDeleteClick = async () => {
    if (illustration) {
      setIsDeleting(true);
      await illustrationModel.delete(illustration);
      closeShowDelete();
      module.reloadItem();
      setIsDeleting(false);
    }
  };

  if (!illustration) {
    return null;
  }

  return (
    <Wrapper>
      {illustration.illustration && <ImgSvg src={getFile()} style={{ backgroundColor: BG_COLOR[darkMode] }} />}
      <Footer>
        {illustration.illustration && (
          <span style={{ fontSize: 12, cursor: 'pointer' }} onClick={toggleType}>
            {FILE_FORMAT[extension]}
          </span>
        )}
        {illustration.illustration && <Icon icon={faMoon} onClick={toggleDarkMode} />}
        {illustration.illustration && <Icon icon={faDownload} onClick={download} />}
        <Icon icon={faUpload} onClick={handleShowUpload} />
        {illustration.illustration && <Icon icon={faTrash} onClick={handleShowDelete} />}
      </Footer>
      {showUpload && (
        <ModalWrapper onClose={closeShowUpload} title={'Upload PNG & SVG'} width={320} height={200}>
          <EditorRowStyled>
            <span>PNG: </span>
            <FileUploadStyled handleFile={handleFileChosenPNG} />
          </EditorRowStyled>
          <EditorRowStyled>
            <span>SVG: </span>
            <FileUploadStyled handleFile={handleFileChosenSVG} />
          </EditorRowStyled>
          <EditorRowStyled>
            <Button
              variant="contained"
              size="small"
              disabled={!(fileSVG && filePNG) || isUploading}
              onClick={handleUploadClick}
            >
              Upload
            </Button>
            {isUploading && <CircularProgress size={20} />}
          </EditorRowStyled>
          <p dangerouslySetInnerHTML={{ __html: message }} />
        </ModalWrapper>
      )}
      {showDelete && (
        <ModalWrapper onClose={closeShowDelete} title={''} width={120} height={60}>
          <EditorRowStyled>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              disabled={isDeleting}
              onClick={handleDeleteClick}
            >
              Delete Img
            </Button>
            {isDeleting && <CircularProgress size={20} />}
          </EditorRowStyled>
        </ModalWrapper>
      )}
    </Wrapper>
  );
};

export default Illustration;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e4e4e4;
  border-radius: 4px;
  padding: 2px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 4px 4px 4px 10px;
`;

const ImgSvg = styled.img`
  width: 250px;
  height: 190px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 6px;
  cursor: pointer;
  color: #607d8b;
`;

const EditorRowStyled = styled(EditorRow)`
  justify-content: flex-start;
  margin-top: 10px;
`;

const FileUploadStyled = styled(FileUpload)`
  margin-right: 5px;
`;
