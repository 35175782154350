import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import { useForceRender } from 'hooks/useForceRender';
import ConceptUseModel, { IConceptUse } from 'models/ConceptUseModel';
import React, { useCallback, useContext, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { tabsOpened } from 'store/actions/tabs';
import styled from 'styled-components';
import { IGridData } from 'types/models';
import Grid from 'CMFW/grid/Grid';
import DialectSelect, { IDialectOption } from '../../components/selects/DialectSelect';

type IProps = {
  dialectId: number;
};

export const ConceptUse: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();
  const module = useContext(ModuleContext);
  const { courseId, ref, itemId } = module;

  const [dialectSelect, setDialectSelect] = React.useState(0);

  const dispatch = useDispatch();

  const usesModel = useMemo(
    () => new ConceptUseModel({ render: forceRender, courseId, ref, refId: itemId, targetDialectId: props.dialectId }),
    [forceRender, courseId, ref, itemId, props.dialectId],
  );

  const handleLoad = useCallback(() => {
    usesModel.getUses(dialectSelect);
  }, [usesModel, dialectSelect]);

  const handleDialectChange = (dialect: IDialectOption) => {
    setDialectSelect(dialect.dialectId);
  };

  const handleRowClick = (data: IGridData) => {
    const use = data as IConceptUse;
    dispatch(tabsOpened({ courseId, refId: use.ref, search: use.refId.toString() }));
  };

  return (
    <Container>
      <EditorRow>
        <DialectSelect onlySource handleDialectChange={handleDialectChange} />
        <Button variant="outlined" size="small" onClick={handleLoad}>
          load
        </Button>
      </EditorRow>
      <ModuleContext.Provider value={usesModel}>
        <GridStyled handleRowClick={handleRowClick} />
      </ModuleContext.Provider>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  & div[col-id='refId'] {
    text-decoration: underline;
  }
  & .ag-row {
    cursor: pointer;
  }
`;

const GridStyled = styled(Grid)`
  && {
    height: calc(100% - 30px);
  }
`;

const EditorRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  & > div {
    margin: 0 10px;
  }
`;
