import { default as ModuleContext } from 'CMFW/context/ModuleContext';
import { ModalWrapper } from 'components/ModalWrapper';
import { useForceRender } from 'hooks/useForceRender';
import React, { useContext, useEffect, useMemo } from 'react';
import { ContainerRight, EditorGap, EditorRow } from 'components/common/styles';
import TranslationModel from '../../../models/TranslationModel';
import { IRefIds } from '../../../constants/refs';
import SaveButton from '../../../CMFW/button/SaveButton';
import Translations from '../../../components/translation/Translations';
import ItemLayout from '../../../CMFW/layout/ItemLayout';
import { LoadingItem } from '../../../CMFW/layout/Loading';
import styled from 'styled-components';
import { ITranslation } from '../../../types/Translation';

type IProps = {
  onClose: () => void;
  onUpdated: (translationId: number, dialects: ITranslation[]) => void;
  translationId: number;
};

const ParagraphEditModal: React.FC<IProps> = (props) => {
  const forceRender = useForceRender();

  const moduleModel = useContext(ModuleContext);

  const translationModel = useMemo(
    () => new TranslationModel({ render: forceRender, courseId: moduleModel.courseId }),
    [forceRender, moduleModel.courseId],
  );

  useEffect(() => {
    translationModel.loadItemEdit(props.translationId);
  }, [translationModel, props.translationId]);

  const onSave = () => {
    const dialects = translationModel.getItemUpdatedValue('dialects') as ITranslation[];
    props.onUpdated(props.translationId, dialects);
  };

  return (
    <ModalWrapper title="Edit Paragraph" width={700} height={600} onClose={props.onClose}>
      <ModuleContext.Provider value={translationModel}>
        <ContainerRight>
          <ItemLayout>
            <EditorRow>
              <EditorGap />
              <SaveButton onSave={onSave} />
            </EditorRow>
            <Scroll>
              <Translations keyName={'dialects'} refId={IRefIds.paragraph} />
            </Scroll>
          </ItemLayout>
          <LoadingItem />
        </ContainerRight>
      </ModuleContext.Provider>
    </ModalWrapper>
  );
};

export default ParagraphEditModal;

const Scroll = styled.div`
  height: 100%;
  overflow: auto;
`;
