import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { EditorRow, EditorGapGreedy } from '../../../components/common/styles';
import { ModalWrapper } from '../../../components/ModalWrapper';
import TextEditor from '../../../CMFW/input/TextEditor';
import { TranslationStatusSelect } from '../../../components/translation/TranslationStatusSelect';

type IProps = {
  title: string;
  status: number;
  handleChangeTitle: (title: string, status: number) => void;
  onClose: () => void;
};

const LessonOutlineTitleModal: React.FC<IProps> = (props) => {
  const [title, setTitle] = useState(props.title);
  const [status, setStatus] = useState(props.status);

  const handleChangeTitle = (text: string) => {
    setTitle(text);
  };

  const handleChangeStatus = (status: number) => {
    setStatus(status);
  };

  const onUpdate = () => {
    props.handleChangeTitle(title, status);
  };

  return (
    <ModalWrapper title={'Edit Title'} onClose={props.onClose} width={500} height={200}>
      <EditorRow>
        <TranslationStatusSelect status={props.status} onChange={handleChangeStatus} />
        <TextEditor
          keyName={''}
          config={{
            toolBarInline: [['lang_target']],
          }}
          autoGrow
          text={title}
          onChangeText={handleChangeTitle}
        />
      </EditorRow>
      <EditorGapGreedy />
      <Button variant="contained" size="small" color="primary" onClick={onUpdate}>
        update
      </Button>
    </ModalWrapper>
  );
};

export default LessonOutlineTitleModal;
