export enum IRefIds {
  NONE = 0,
  admin = 1,
  courses = 2,
  vocs = 3,
  vocsCourse = 5,
  sentences = 6,
  audio = 16,
  publish = 17,
  vocabularyCategories = 19,
  exercise = 24,
  reading = 25,
  section = 29,
  clUtils = 31,
  panelIntro = 32,
  klines = 33,
  panels = 34,
  lessons = 35,
  glossaries = 36,
  compounds = 38,
  boards = 39,
  readingExercise = 40,
  cultural = 43,
  explanations = 45,
  sourceAdaptation = 47,
  items = 48,
  panelsFactory = 49,
  conjugation = 50,
  titles = 51, //lesson & panel (source adaptation)
  conceptLinker = 52,
  segment = 53,
  lessonOutline = 44,
  dialog = 54,
  dialogSegment = 66,
  dialogSpeaker = 55,
  panelTitle = 56,
  lessonTitle = 57,
  readingText = 58,
  heading = 59,
  compoundTypes = 62,
  languages = 63,
  feedback = 64,
  paragraph = 65,
  tableLiterals = 67,
  table = 68,
  chart = 69,
  grid_words = 70,
  quote_sentence = 71,
  panel_img = 72,
  grid_voc = 73,
  translation = 74,
  SDL = 77,
  book = 78,
  bookChapter = 79,
  bookChapterText = 80,
  trainer = 81,
  trainerSegment = 82,
  ttsLab = 83,
  aIReview = 85,
  conjugationTemplate = 1002,
  wordClass = 1003,
  conjugationRule = 1003,
  blockNames = 1004,
  users = 1004,
  translationProject = 1005,
  ttsLabVoice = 1006,
}
