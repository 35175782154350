import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import ModuleContext from 'CMFW/context/ModuleContext';
import { TextFieldStyled } from 'components/common/SelectInput';
import { EditorGap, EditorRow } from 'components/common/styles';
import DialectSelect, { IDialectOption } from 'components/selects/DialectSelect';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

type IProps = {
  handleSearch?: (text: string, dialect: string) => void;
  onlyTarget?: boolean;
  onlySource?: boolean;
  titleOption?: boolean;
  hideDialectSelect?: boolean;
  handleCancel?(): void;
  showCancelButton?: boolean;
  onSearch?: () => void;
  className?: string;
};

const SearchBarDialect: React.FC<IProps> = (props) => {
  const [text, setText] = useState('');
  const [dialect, setDialect] = useState('');

  const module = useContext(ModuleContext);

  const search = (searchText?: string) => {
    props.handleSearch && props.handleSearch(searchText ? searchText : text, dialect);
    module.search({
      text: searchText ? searchText : text,
      dialect,
    });
    props.onSearch && props.onSearch();
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleDialectChange = useCallback((selectedDialect: IDialectOption) => {
    setDialect(selectedDialect.dialectCode);
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      search();
    }
  };

  const doSearch = () => {
    search();
  };

  return (
    <EditorRow className={props.className}>
      <TextFieldRestyled variant="outlined" onChange={onChange} onKeyDown={handleKeyDown} label={'Search'} />
      {!props.hideDialectSelect && (
        <>
          <DialectSelectStyled
            titleOption={props.titleOption}
            handleDialectChange={handleDialectChange}
            onlyTarget={props.onlyTarget}
            onlySource={props.onlySource}
          />
        </>
      )}
      {props.handleCancel && props.showCancelButton && (
        <>
          <EditorGap />
          <ButtonStyled variant="outlined" size="small" onClick={props.handleCancel}>
            <FontAwesomeIcon icon={faWindowClose} />
          </ButtonStyled>
        </>
      )}
      <SearchButton variant="outlined" size="small" onClick={doSearch}>
        search
      </SearchButton>
    </EditorRow>
  );
};

const TextFieldRestyled = styled(TextFieldStyled)`
  width: auto;
  flex-grow: 1;
  && {
    margin-right: 0;
  }
  & .MuiInputBase-root {
    height: 33px;
  }
`;

const DialectSelectStyled = styled(DialectSelect)`
  && {
    margin-right: 0;
  }
`;

const ButtonStyled = styled(Button)`
  && {
    min-width: 32px;
    height: 100%;
  }
`;

const SearchButton = styled(Button)`
  && {
    height: 33px;
    margin-left: 10px;
  }
`;

export default SearchBarDialect;
