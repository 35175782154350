import { ContainerRight } from 'components/common/styles';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useForceRender } from '../../../hooks/useForceRender';
import VocCategoryVocsModel from '../../../models/VocCategoryVocsModel';
import Grid from '../../../CMFW/grid/Grid';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import { IGridData } from '../../../types/models';
import { tabsOpened } from '../../../store/actions/tabs';
import { IVoc } from '../../../models/VocabularyModel';
import { IRefIds } from '../../../constants/refs';
import { useDispatch } from 'react-redux';

const VocCategoryVocs: React.FC = () => {
  const forceRender = useForceRender();
  const vocCategoryModel = useContext(ModuleContext);
  const dispatch = useDispatch();

  const vocCategoryVocsModel = useMemo(() => new VocCategoryVocsModel({ render: forceRender, courseId: 0 }), [
    forceRender,
  ]);

  useEffect(() => {
    vocCategoryVocsModel.setGrid(vocCategoryModel.getItemValue('vocs'));
  }, [vocCategoryModel.itemUniqueId]);

  useEffect(() => {
    vocCategoryVocsModel.setLoadingGrid(vocCategoryModel.loadingItem);
  }, [vocCategoryModel.loadingItem]);

  const handleRowClick = (data: IGridData) => {
    const use = data as IVoc;
    dispatch(tabsOpened({ courseId: 0, refId: IRefIds.vocs, search: use.id.toString() }));
  };

  return (
    <ContainerRightStyled>
      <ModuleContext.Provider value={vocCategoryVocsModel}>
        <Grid handleRowClick={handleRowClick} />
      </ModuleContext.Provider>
    </ContainerRightStyled>
  );
};

export default VocCategoryVocs;

export const ContainerRightStyled = styled(ContainerRight)``;
