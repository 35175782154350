import { Button, FormControl, FormControlLabel, FormLabel, LinearProgress, Radio, RadioGroup } from '@material-ui/core';
import React, { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ModuleContext from '../../../CMFW/context/ModuleContext';
import TranslationProjectModel from '../../../models/TranslationProjectModel';
import TranslationProjectPreTranslateModel from '../../../models/TranslationProjectPreTranslateModel';
import { useForceRender } from '../../../hooks/useForceRender';

export type IProps = {};

type IMethod = 'memory' | 'google' | 'delete' | 'copy' | 'ai';

const TranslationProjectPreTranslate: React.FC<IProps> = () => {
  const projectModel = useContext(ModuleContext) as TranslationProjectModel;
  const forceRender = useForceRender();
  const [doing, setDoing] = useState(false);
  const [preTranslationDone, setPreTranslationDone] = useState(false);
  const [method, setMethod] = useState<IMethod>('memory');

  const handleDone = async () => {
    setDoing(false);
    setPreTranslationDone(true);
    await projectModel.reloadItem();
  };

  const model = useMemo(
    () =>
      new TranslationProjectPreTranslateModel({
        render: forceRender,
        projectId: projectModel.itemId,
        onDone: handleDone.bind(projectModel),
      }),
    [forceRender, projectModel.itemId],
  );

  useEffect(() => {
    model.stop();
    setDoing(false);
    setPreTranslationDone(false);
  }, [projectModel.itemId]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMethod(event.target.value as IMethod);
  };

  const handlePreTranslate = async () => {
    setDoing(true);
    await model.preTranslate(method);
  };

  const done = model.done.length;
  const total = model.items.length;
  const percent = (done * 100) / total;
  const isEmpty = model.items.length === 0;
  const isLoading = model.items.length === 0;

  return (
    <Wrapper>
      <FormControl component="fieldset">
        <FormLabel component="legend">Pre-Translate</FormLabel>
        <RadioGroup
          aria-label="pre-translate"
          defaultValue="memory"
          name="radio-buttons-group"
          value={method}
          onChange={handleChange}
        >
          <FormControlLabel value="memory" control={<Radio />} label="Memory" />
          <FormControlLabel value="google" control={<Radio />} label="Google" />
          <FormControlLabel value="ai" control={<Radio />} label="AI" />
          <FormControlLabel value="copy" control={<Radio />} label="Copy from -> to" />
          <FormControlLabel value="delete" control={<Radio />} label="Delete" />
        </RadioGroup>
      </FormControl>
      {doing ? (
        <ProgressWrapper>
          <Progress variant="determinate" value={isLoading ? 0 : percent} />
          <Indicator>{isLoading ? 'Loading ...' : isEmpty ? '-' : `${done}/${total}`}</Indicator>
        </ProgressWrapper>
      ) : (
        <>
          <Button variant="contained" size="small" onClick={handlePreTranslate}>
            pre-translate
          </Button>
          {preTranslationDone && <p>Pre-translate done.</p>}
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  margin: 30px;
`;
const ProgressWrapper = styled.div`
  position: relative;
  width: 180px;
  margin-bottom: 10px;
`;

const Progress = styled(LinearProgress)`
  margin-top: 2px;
  && {
    height: 20px;
  }
`;

const Indicator = styled.span`
  position: absolute;
  bottom: 2px;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  text-shadow: 0.1em 0.1em 1px #424242;
`;

export default TranslationProjectPreTranslate;
