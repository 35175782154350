import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { IRefIds } from '../../../../constants/refs';
import {
  IPanelEstructureBlockElement,
  IPanelEstructureElementChart,
  IPanelEstructureElementConj,
  IPanelEstructureElementGridVoc,
  IPanelEstructureElementGridWords,
  IPanelEstructureElementImg,
  IPanelEstructureElementItem,
  IPanelEstructureElementParagraph,
  IPanelEstructureElementQuoteSentence,
  IPanelEstructureElementSentence,
  IPanelEstructureElementTable,
  IPanelEstructureElementVoc,
} from '../../../../types/panelEstructure';
import DragHandle from '../DragHandle';
import ModuleContext from '../../../../CMFW/context/ModuleContext';
import PanelEstructureModel from '../../../../models/PanelEstructureModel';
import { tabsOpened } from '../../../../store/actions/tabs';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../../store/reducers/rootReducer';
import { ICourseConfig } from '../../../../store/reducers/courseConfig';
import PanelBlockElementVoc from '../element/PanelBlockElementVoc';
import PanelBlockElementGridVoc from '../element/PanelBlockElementGridVoc';
import PanelBlockElementSentence from '../element/PanelBlockElementSentence';
import PanelBlockElementItem from '../element/PanelBlockElementItem';
import PanelBlockElementParagraph from '../element/PanelBlockElementParagraph';
import PanelBlockElementConjugation from '../element/PanelBlockElementConjugation';
import PanelBlockElementTable from '../element/PanelBlockElementTable';
import PanelBlockElementChart from '../element/PanelBlockElementChart';
import PanelBlockElementGridWords from '../element/PanelBlockElementGridWords';
import PanelBlockElementQuoteSentence from '../element/PanelBlockElementQuoteSentence';
import PanelBlockElementImg from '../element/PanelBlockElementImg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import ConjSearchModal, { IConjSearchModal } from '../../modal/ConjSearchModal';
import VocGridEditModal from '../../modal/VocGridEditModal';
import WordGridEditModal from '../../modal/WordGridEditModal';
import QuoteSentenceEditModal from '../../modal/QuoteSentenceEditModal';
import ImgSrcEditModal from '../../modal/ImgSrcEditModal';
import ParagraphEditModal from '../../modal/ParagraphEditModal';
import PanelEstructureContext from '../context/PanelEstructureContext';

export type IProps = {
  element: IPanelEstructureBlockElement;
  index: number;
};

const PanelEstructureElement: React.FC<IProps> = (props) => {
  const [modal, setModal] = useState(IRefIds.NONE);
  const module = useContext(ModuleContext) as PanelEstructureModel;
  const dispatch = useDispatch();
  const courses = useSelector((state: IAppState) => state.courseConfig);
  const panelEstructureContext = useContext(PanelEstructureContext);

  const openModal = (modal: IRefIds) => setModal(modal);

  const closeModal = () => setModal(IRefIds.NONE);

  const openId = () => {
    dispatch(
      tabsOpened({
        refId: props.element.ref,
        search: props.element.itemId.toString(),
        courseId: module.courseId,
      }),
    );
  };

  const onUpdateConj = async (data: IConjSearchModal) => {
    closeModal();
    await module.updateElement({
      elementId: props.element.elementId,
      data: [data.tableId, data.ref, data.id].join(','),
    });
  };

  const onUpdateVocGrid = async (vocIds: number[]) => {
    closeModal();
    await module.updateElement({
      elementId: props.element.elementId,
      data: vocIds.join(', '),
    });
  };

  const onUpdateWordsGrid = async (words: string[]) => {
    closeModal();
    await module.updateElement({
      elementId: props.element.elementId,
      data: words.join(', '),
    });
  };

  const onUpdateQuoteSentence = async (quoteSentence: string) => {
    closeModal();
    await module.updateElement({
      elementId: props.element.elementId,
      data: quoteSentence,
    });
  };

  const onUpdateImgSrc = async (src: string) => {
    closeModal();
    await module.updateElement({
      elementId: props.element.elementId,
      data: src,
    });
  };

  const onChangeDialect = async (dialect: number) => {
    dialect = dialect === props.element.dialect ? 0 : dialect;
    await module.updateElementDialect({
      elementId: props.element.elementId,
      dialect,
    });
  };

  const renderDialects = () => {
    const courseConfig = courses[module.courseId] as ICourseConfig | undefined;
    if (!courseConfig || courseConfig.targetDialects.length === 1) {
      return null;
    }

    return (
      <>
        {courseConfig.targetDialects.map((dialect) => (
          <DialectSelect
            key={dialect.dialectId}
            onClick={() => onChangeDialect(dialect.dialectId)}
            selected={dialect.dialectId === props.element.dialect}
          >
            {dialect.dialectCode}
          </DialectSelect>
        ))}
      </>
    );
  };

  const renderTopBar = (provided: DraggableProvided) => {
    const names: { [key in IRefIds]?: string } = {
      [IRefIds.vocs]: 'Vocabulary',
      [IRefIds.grid_voc]: 'Vocabulary Grid',
      [IRefIds.sentences]: 'Sentence',
      [IRefIds.items]: 'Item',
      [IRefIds.paragraph]: 'Paragraph',
      [IRefIds.conjugation]: 'Conjugation',
      [IRefIds.table]: 'Table',
      [IRefIds.chart]: 'Chart',
      [IRefIds.grid_words]: 'Words Grid',
      [IRefIds.quote_sentence]: 'Quote Sentence',
      [IRefIds.panel_img]: 'Img',
    };
    const editable: { [key in IRefIds]?: boolean } = {
      [IRefIds.vocs]: false,
      [IRefIds.grid_voc]: true,
      [IRefIds.sentences]: false,
      [IRefIds.items]: false,
      [IRefIds.paragraph]: true,
      [IRefIds.conjugation]: true,
      [IRefIds.table]: false,
      [IRefIds.chart]: false,
      [IRefIds.grid_words]: true,
      [IRefIds.quote_sentence]: true,
      [IRefIds.panel_img]: true,
    };

    return (
      <ElementTopBar>
        {panelEstructureContext.editable && (
          <DragHandle className={'hover_drag_handle_element'} {...provided.dragHandleProps} />
        )}
        {renderDialects()}
        {props.element.itemId !== 0 && props.element.ref !== IRefIds.paragraph && (
          <Link onClick={openId}>{props.element.itemId.toString()}</Link>
        )}
        {<ElementName>{(names[props.element.ref] ?? '').toUpperCase()}</ElementName>}
        {panelEstructureContext.editable && editable[props.element.ref] && (
          <Icon size={'xs'} icon={faPencilAlt} onClick={() => openModal(props.element.ref)} />
        )}
      </ElementTopBar>
    );
  };

  return (
    <Draggable
      isDragDisabled={!panelEstructureContext.editable}
      draggableId={`element-${props.element.elementId}`}
      index={props.index}
    >
      {(provided) => (
        <WrapperActivity {...provided.draggableProps} ref={provided.innerRef}>
          {renderTopBar(provided)}
          {props.element.ref === IRefIds.vocs && (
            <PanelBlockElementVoc data={props.element.data as IPanelEstructureElementVoc} />
          )}
          {props.element.ref === IRefIds.grid_voc && (
            <PanelBlockElementGridVoc data={props.element.data as IPanelEstructureElementGridVoc} />
          )}
          {props.element.ref === IRefIds.sentences && (
            <PanelBlockElementSentence data={props.element.data as IPanelEstructureElementSentence} />
          )}
          {props.element.ref === IRefIds.items && (
            <PanelBlockElementItem data={props.element.data as IPanelEstructureElementItem} />
          )}
          {props.element.ref === IRefIds.paragraph && (
            <PanelBlockElementParagraph data={props.element.data as IPanelEstructureElementParagraph} />
          )}
          {props.element.ref === IRefIds.conjugation && (
            <PanelBlockElementConjugation data={props.element.data as IPanelEstructureElementConj} />
          )}
          {props.element.ref === IRefIds.table && (
            <PanelBlockElementTable data={props.element.data as IPanelEstructureElementTable} />
          )}
          {props.element.ref === IRefIds.chart && (
            <PanelBlockElementChart data={props.element.data as IPanelEstructureElementChart} />
          )}
          {props.element.ref === IRefIds.grid_words && (
            <PanelBlockElementGridWords data={props.element.data as IPanelEstructureElementGridWords} />
          )}
          {props.element.ref === IRefIds.quote_sentence && (
            <PanelBlockElementQuoteSentence data={props.element.data as IPanelEstructureElementQuoteSentence} />
          )}
          {props.element.ref === IRefIds.panel_img && (
            <PanelBlockElementImg data={props.element.data as IPanelEstructureElementImg} />
          )}
          {modal === IRefIds.conjugation && <ConjSearchModal onClose={closeModal} onChooseConj={onUpdateConj} />}
          {modal === IRefIds.grid_voc && (
            <VocGridEditModal
              onClose={closeModal}
              vocIds={(props.element.data as IPanelEstructureElementGridVoc).vocs.map((v) => v.id)}
              onEditVocIds={onUpdateVocGrid}
            />
          )}
          {modal === IRefIds.grid_words && (
            <WordGridEditModal
              onClose={closeModal}
              words={(props.element.data as IPanelEstructureElementGridWords).gridWords}
              onEditWords={onUpdateWordsGrid}
            />
          )}
          {modal === IRefIds.quote_sentence && (
            <QuoteSentenceEditModal
              onClose={closeModal}
              quoteSentence={(props.element.data as IPanelEstructureElementQuoteSentence).quoteSentence}
              onEditQuoteSentence={onUpdateQuoteSentence}
            />
          )}
          {modal === IRefIds.panel_img && (
            <ImgSrcEditModal
              onClose={closeModal}
              src={(props.element.data as IPanelEstructureElementImg).src}
              onEditSrc={onUpdateImgSrc}
            />
          )}
          {modal === IRefIds.paragraph && (
            <ParagraphEditModal
              onClose={closeModal}
              translationId={(props.element.data as IPanelEstructureElementParagraph).translationId}
              onUpdated={module.updateParagraphTranslation.bind(module)}
            />
          )}
        </WrapperActivity>
      )}
    </Draggable>
  );
};

export default PanelEstructureElement;

export const WrapperActivity = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 6px 10px;
  font-size: 14px;
  & :hover .hover_drag_handle_element {
    opacity: 1;
  }
`;

const ElementTopBar = styled.div`
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #c0c0c0;
`;

const Link = styled.span`
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  margin: 0 5px;
`;

const ElementName = styled.span`
  font-size: 12px;
  margin: 0 5px;
`;

const DialectSelect = styled.span<{ selected: boolean }>`
  cursor: pointer;
  font-size: 12px;
  background-color: ${(props) => (props.selected ? '#c256de' : 'transparent')};
  color: ${(props) => (props.selected ? 'white' : 'inherent')};
  padding: 2px 4px;
  border-radius: 2px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin: 0 5px;
  cursor: pointer;
`;
